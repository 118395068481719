import { useLocalStorage } from "@vueuse/core";
import { Howl, Howler } from "howler";
import { defineStore } from "pinia";
import type { SoundsList } from "../plugins/audio.client";

export const useAudioStore = defineStore("audio", () => {
    const soundsList = ref<Map<SoundsList, Howl>>(new Map());
    const volume = useLocalStorage("volume", 0.2);

    const register = (name: SoundsList, path: string) => {
        const howl = new Howl({
            src: path
        });

        soundsList.value.set(name, howl);

        return howl;
    };

    const play = (name: SoundsList) => {
        const sound = soundsList.value.get(name);

        if (!sound) {
            return;
        }

        return sound.play();
    };

    const getHowlInstance = (name: SoundsList) => soundsList.value.get(name);

    const load = () => Howler.volume(volume.value);

    watch(volume, Howler.volume);

    return { register, play, volume, getHowlInstance, load };
});

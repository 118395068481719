import { useToast } from "#imports";
import type { SoundsList } from "~/plugins/audio.client";

export function useCustomToast(): ReturnType<typeof useToast> {
    const audio = useAudioStore();

    const hook = useToast();
    const add = hook.add;

    const matchingAudioByColor = {
        red: "notification_error",
        green: "notification_success"
    };

    hook.add = (...params: Parameters<typeof hook.add>) => {
        const sound = matchingAudioByColor[params[0].color as keyof typeof matchingAudioByColor] as SoundsList | undefined;
        sound && audio.play(sound);

        return add(...params);
    };

    return hook;
}

import { defineStore } from "pinia";

export const useEmojiStore = defineStore("emojis", () => {
    const route = useRoute();
    const emojis = ref<EmojiInterface[]>([]);

    const load = async () => {
        const { data, error } = await useApi<
            PaginatedInterface<EmojiInterface>
        >("emojis", {
            query: {
                perPage: 1000
            }
        });

        if (error.value) { return; }

        emojis.value = data.value?.data ?? [];
    };

    const find = (code: string) => {
        return emojis.value.find(emoji => emoji.code === code);
    };

    return {
        emojis,
        load,
        find
    };
});

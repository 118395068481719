export default {
  "/sounds/cash_out.wav": {
    "src": "/sounds/cash_out.wav",
    "volume": 1
  },
  "/sounds/click.mp3": {
    "src": "/sounds/click.mp3",
    "volume": 1
  },
  "/sounds/click.wav": {
    "src": "/sounds/click.wav",
    "volume": 1
  },
  "/sounds/money_going_up.wav": {
    "src": "/sounds/money_going_up.wav",
    "volume": 1
  },
  "/sounds/select_skin.wav": {
    "src": "/sounds/select_skin.wav",
    "volume": 1
  },
  "/sounds/slot_win_1.wav": {
    "src": "/sounds/slot_win_1.wav",
    "volume": 1
  },
  "/sounds/slot_win_2.wav": {
    "src": "/sounds/slot_win_2.wav",
    "volume": 1
  },
  "/sounds/slot_win_3.wav": {
    "src": "/sounds/slot_win_3.wav",
    "volume": 1
  },
  "/sounds/battles/beep.wav": {
    "src": "/sounds/battles/beep.wav",
    "volume": 1
  },
  "/sounds/battles/lock.mp3": {
    "src": "/sounds/battles/lock.mp3",
    "volume": 1
  },
  "/sounds/cases/open.wav": {
    "src": "/sounds/cases/open.wav",
    "volume": 1
  },
  "/sounds/cases/roll.wav": {
    "src": "/sounds/cases/roll.wav",
    "volume": 1
  },
  "/sounds/cursor/hover_a.wav": {
    "src": "/sounds/cursor/hover_a.wav",
    "volume": 1
  },
  "/sounds/cursor/hover_b.wav": {
    "src": "/sounds/cursor/hover_b.wav",
    "volume": 1
  },
  "/sounds/cursor/hover_c.wav": {
    "src": "/sounds/cursor/hover_c.wav",
    "volume": 1
  },
  "/sounds/coinflip/flip_v2.wav": {
    "src": "/sounds/coinflip/flip_v2.wav",
    "volume": 1
  },
  "/sounds/coinflip/no_flip_v2.wav": {
    "src": "/sounds/coinflip/no_flip_v2.wav",
    "volume": 1
  },
  "/sounds/rust/skull-torch-strike-lit-01.wav": {
    "src": "/sounds/rust/skull-torch-strike-lit-01.wav",
    "volume": 1
  },
  "/sounds/rust/zipline-mount-01.wav": {
    "src": "/sounds/rust/zipline-mount-01.wav",
    "volume": 1
  },
  "/sounds/mines/lose.wav": {
    "src": "/sounds/mines/lose.wav",
    "volume": 1
  },
  "/sounds/mines/win.wav": {
    "src": "/sounds/mines/win.wav",
    "volume": 1
  },
  "/sounds/test/bet.wav": {
    "src": "/sounds/test/bet.wav",
    "volume": 1
  },
  "/sounds/test/info_button.wav": {
    "src": "/sounds/test/info_button.wav",
    "volume": 1
  },
  "/sounds/test/notification_error.wav": {
    "src": "/sounds/test/notification_error.wav",
    "volume": 1
  },
  "/sounds/test/notification_success.wav": {
    "src": "/sounds/test/notification_success.wav",
    "volume": 1
  },
  "/sounds/test/primary_button.wav": {
    "src": "/sounds/test/primary_button.wav",
    "volume": 1
  },
  "/sounds/test/secondary_button.wav": {
    "src": "/sounds/test/secondary_button.wav",
    "volume": 1
  },
  "/sounds/tower/lose.wav": {
    "src": "/sounds/tower/lose.wav",
    "volume": 1
  },
  "/sounds/tower/win.wav": {
    "src": "/sounds/tower/win.wav",
    "volume": 1
  },
  "/sounds/upgrader/fast_lose.wav": {
    "src": "/sounds/upgrader/fast_lose.wav",
    "volume": 1
  },
  "/sounds/upgrader/fast_win.wav": {
    "src": "/sounds/upgrader/fast_win.wav",
    "volume": 1
  },
  "/sounds/upgrader/lose.wav": {
    "src": "/sounds/upgrader/lose.wav",
    "volume": 1
  },
  "/sounds/upgrader/win.wav": {
    "src": "/sounds/upgrader/win.wav",
    "volume": 1
  },
  "/sounds/wheel/v2_a.wav": {
    "src": "/sounds/wheel/v2_a.wav",
    "volume": 1
  },
  "/sounds/wheel/v2_b.wav": {
    "src": "/sounds/wheel/v2_b.wav",
    "volume": 1
  },
  "/sounds/wheel/v2_c.wav": {
    "src": "/sounds/wheel/v2_c.wav",
    "volume": 1
  },
  "/sounds/wheel/v2_d.wav": {
    "src": "/sounds/wheel/v2_d.wav",
    "volume": 1
  }
}
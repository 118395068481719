import { ModalsConfirmation } from "#components";
import { add } from "date-fns";

export function useHelper () {
    const modal = useModal();

    const binomialCoefficient = (n: number, k: number): number => {
        if (k === 0 || k === n) { return 1; }

        return binomialCoefficient(n - 1, k - 1) + binomialCoefficient(n - 1, k);
    };

    const binomialDistribution = (n: number, p: number, k: number): number => {
        const coefficient = binomialCoefficient(n, k);
        const probability = coefficient * Math.pow(p, k) * Math.pow(1 - p, n - k);
        return probability;
    };

    const dateToDateTimeLocal = (date: Date | string): string => {
        const copy = new Date(date);
        copy.setMinutes(copy.getMinutes() - copy.getTimezoneOffset());
        return copy.toISOString().slice(0, 16);
    };

    const countryCodeToFlag = (countryCode: string): string => {
        return countryCode.toUpperCase().replace(/./g, char => String.fromCodePoint(char.charCodeAt(0) + 127397));
    };

    const itemNameColorByPrice = (price: number) => {
        if (price >= 450) { return "#F79248"; }
        if (price >= 115) { return "#FF4049"; }
        if (price >= 50) { return "#BD48F7"; }
        if (price >= 25) { return "#48F76C"; }
        if (price >= 1) { return "#48D4F7"; }
        return "#C3C3C3";
    };

    // https://github.com/DoctorMcKay/node-steam-tradeoffer-manager/blob/master/resources/ETradeOfferState.js
    const colorFromTradeOfferState = (state: number) => {
        if ([3].includes(state)) { return "green"; }
        if ([2, 9, 11].includes(state)) { return "yellow"; }
        if (![2, 3, 9, 11].includes(state)) { return "red"; }

        return "blue";
    };

    const durationStringToDateTime = (durationString: string): Date | null => {
        const durations = [
            { aliases: ["y", "year", "years"], key: "years" },
            { aliases: ["mo", "month", "months"], key: "months" },
            { aliases: ["w", "week", "weeks"], key: "weeks" },
            { aliases: ["d", "day", "days"], key: "days" },
            { aliases: ["h", "hour", "hours"], key: "hours" },
            { aliases: ["m", "minute", "minutes"], key: "minutes" },
            { aliases: ["s", "second", "seconds"], key: "seconds" }
        ];

        let date = new Date();
        const matches = durationString.match(/(\d+)\s?([a-z]+)/g);

        if (!matches) { return null; }

        for (const match of matches) {
            const durationPartMatch = match.match(/^(\d+)\s?([a-z]+)$/);
            if (!durationPartMatch) { continue; }

            const durationPartValue = parseInt(durationPartMatch[1]);
            const durationPartUnit = durationPartMatch[2];

            const duration = durations.find((d) => d.aliases.includes(durationPartUnit));
            if (!duration) { continue; }

            date = add(date, { [duration.key]: durationPartValue });
        }

        return date;
    };

    const openConfirmationModal = (title: string, description: string) => {
        return new Promise((resolve) => {
            modal.open(ModalsConfirmation, {
                title,
                description,
                onAnswer: (confirmed: boolean) => resolve(confirmed),
                onClose: () => resolve(false)
            });
        });
    };

    return {
        binomialCoefficient,
        binomialDistribution,
        dateToDateTimeLocal,
        countryCodeToFlag,
        itemNameColorByPrice,
        colorFromTradeOfferState,
        durationStringToDateTime,
        openConfirmationModal
    };
}

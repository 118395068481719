export default defineNuxtPlugin(async () => {
    const route = useRoute();
    const socket = useSocketStore()();

    if (route.path.startsWith("/block")) {
        socket.load();
        return;
    }

    const games = useGameStore();
    const emojis = useEmojiStore();
    const config = useConfigStore();

    await Promise.all([emojis.load(), games.load(), socket.load(), config.load()]);
});

export default defineNuxtRouteMiddleware((to) => {
    const auth = useAuthStore();
    const toast = useCustomToast();

    if (to.path.startsWith("/dashboard") && !auth.hasPermission("access_dashboard")) {
        toast.add({
            title: "You do not have access to this page",
            color: "red"
        });

        return navigateTo("/");
    }
});

export default defineNuxtPlugin({
    parallel: true,
    async setup () {
        const route = useRoute();

        if (route.path.startsWith("/block")) {
            return;
        }

        const cryptos = useCryptoStore();
        await cryptos.load();
    }
});

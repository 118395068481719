import { defineStore } from "pinia";

export const useCryptoStore = defineStore("crypto", () => {
    const cryptos = ref<CryptoInterface[]>([]);
    const toast = useCustomToast();

    const load = async () => {
        const { data, error } = await useApi<
            PaginatedInterface<CryptoInterface>
        >("cryptos?perPage=1000");

        if (error.value) {
            return;
        }

        cryptos.value = data.value!.data;

        const ws = useSocketStore()();

        ws.register("crypto:updated", (data: CryptoInterface[]) => {
            cryptos.value = data;
        });
    };

    const getMultiplierByCurrency = (currency: string) => {
        const crypto = cryptos.value.find(c => c.currency === currency);
        return crypto?.multiplier || 1;
    };

    const getUSDPriceByCurrency = (currency: string) => {
        const crypto = cryptos.value.find(c => c.currency === currency);
        return 0;
    };

    return {
        cryptos,

        load,
        getMultiplierByCurrency,
        getUSDPriceByCurrency
    };
});

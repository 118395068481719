import { useImageStore } from "../stores/image";

export default defineNuxtPlugin(() => {
    const images = useImageStore();

    images.add("flipBlack", "/sprites/coinflip/flip-black.png");
    images.add("flipBlue", "/sprites/coinflip/flip-blue.png");
    images.add("noFlipBlack", "/sprites/coinflip/noflip-black.png");
    images.add("noFlipBlue", "/sprites/coinflip/noflip-blue.png");
});

import { Crisp } from "crisp-sdk-web";

export default defineNuxtPlugin({
    parallel: true,
    setup: async (nuxtApp) => {
        if (!nuxtApp.$config.public.crispWebsiteId) {
            return;
        }

        Crisp.configure(nuxtApp.$config.public.crispWebsiteId);

        const auth = useAuthStore();
        const request = useRequestURL();

        if (auth.user) {
            await $crisp.push(["set", "session:data", ["dashoard", `${request.origin}/dashboard/users/${auth.user.uuid}`]]);
            await $crisp.push(["set", "session:data", ["user_uuid", auth.user.uuid]]);
            await $crisp.push(["set", "session:data", ["roles", auth.user.roles.map(r => r.name).join(", ")]]);
            await $crisp.push(["set", "user:nickname", [auth.user.username]]);
            await $crisp.push(["set", "user:avatar", [auth.user.avatar]]);
        }
    }
});

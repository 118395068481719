<script setup lang="ts">
import { useNetwork } from "@vueuse/core";

const socket = useSocketStore()();
const { isOnline, isSupported } = useNetwork();
</script>

<template>
    <Teleport to="body">
        <div
            v-if="!socket.connected && socket.mightBeErroring"
            class="fixed left-4 right-4 top-4 z-[99999] w-fit border-2 border-skin-red/50 shadow-xl bg-main px-4 py-2"
        >
            {{
                isSupported && !isOnline
                    ? "Please check your internet connection."
                    : "Trying to reconnect, this shouldn't last more than a few seconds."
            }}

            <div class="text-sm text-gray-300">
                You are currently not connected with the server, actions won't
                work until connected.
            </div>
        </div>
    </Teleport>
</template>

<script setup lang="ts">
import { useImage } from "@vueuse/core";

const props = defineProps<{
    src: HTMLImageElement["src"];
    loading?: HTMLImageElement["loading"];
    alt?: HTMLImageElement["alt"];
}>();

const { isLoading } = useImage({
    src: props.src
});
</script>

<template>
    <div
        v-if="isLoading"
        class="flex h-full w-full items-center justify-center gap-4"
    >
        <!-- <div class="h-2 w-2 animate-[bounce_1s_infinite_0ms] rounded-full bg-white/25 shadow-2xl" />
        <div class="h-2 w-2 animate-[bounce_1s_infinite_200ms] rounded-full bg-white/25 shadow-2xl" />
        <div class="h-2 w-2 animate-[bounce_1s_infinite_400ms] rounded-full bg-white/25 shadow-2xl" /> -->
    </div>
    <img
        v-else
        :src="props.src"
        :loading="props.loading"
        :alt="props.alt"
        style="content-visibility: auto"
    />
</template>

import { defineStore } from "pinia";

export const useImageStore = defineStore("image", () => {
    const images = ref<{
        [key: string]: {
            src: string;
            data: MediaImage;
        };
    }>({});

    const add = (name: string, path: string) => {
        const img = new Image();
        img.src = path;

        img.onload = () => {
            images.value[name] = {
                src: path,
                data: img
            };
        };
    };

    const get = (name: string) => {
        return images.value[name];
    };

    return {
        images,
        add,
        get
    };
});

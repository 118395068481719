export default defineNuxtRouteMiddleware((to, from) => {
    const auth = useAuthStore();

    if (!auth.user || !auth.user?.is_two_factor_enabled) return;

    let apiToken = auth.user.apiToken;

    if (!apiToken.two_factor_verified && to.path !== "/auth/two-factor") {
        return navigateTo("/auth/two-factor");
    }
});

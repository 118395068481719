<script setup lang="ts">
const randomId = computed(() => Math.random().toString(36).substr(2, 9));

const stepOrFill = (steps: string[], index: number, fill: string) => {
    if (steps.length === 0) return fill;

    return steps[index] || fill;
};
</script>

<template>
    <slot name="default" :id="randomId" :stepOrFill="stepOrFill" />
</template>

import mitt from "mitt";

export default defineNuxtPlugin(() => {
    const emitter = mitt<{
        [key: string]: any;
    }>();

    return {
        provide: {
            event: emitter.emit,
            listen: emitter.on
        }
    };
});

<script setup lang="ts">
const props = defineProps({
    // primary: {
    //     type: String,
    //     default: "#FFDEE4"
    // },
    // secondary: {
    //     type: Array<string>,
    //     default: ["#934861", "#C2567A"]
    // },
    // tertiary: {
    //     type: Array<string>,
    //     default: ["#5C2C3C", "#8C3E58"]
    // }

    type: {
        type: String,
        default: "heads"
    }
});

const colors = ref<string[]>(["#005de7", "#0050af"]);

if (props.type !== "heads") {
    colors.value = ["#222023", "#0a090a"];
}

const primaryColor = computed(() => colors.value[0]);
const secondaryColor = computed(() => colors.value[1]);

const randomId = computed(() => Math.random().toString(36).substr(2, 9));
</script>

<template>
    <!-- <svg
        width="120"
        height="120"
        viewBox="0 0 120 120"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M120 60C120 93.1371 93.1371 120 60 120C26.8629 120 0 93.1371 0 60C0 26.8629 26.8629 0 60 0C93.1371 0 120 26.8629 120 60Z"
            :fill="'url(#paint0_linear_649_6542' + randomId + ')'"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M118.858 71.7097L99.4775 67.8757C99.9782 65.3444 100.244 62.713 100.244 60C100.244 57.287 99.9782 54.6556 99.4775 52.1243L118.858 48.2903C119.607 52.0775 120 55.9929 120 60C120 64.0071 119.607 67.9225 118.858 71.7097ZM109.894 26.6631L93.4772 37.6538C90.5334 33.2566 86.7434 29.4666 82.3462 26.5228L93.337 10.1061C99.88 14.4866 105.513 20.12 109.894 26.6631ZM71.7097 1.14204L67.8757 20.5225C65.3444 20.0218 62.713 19.7561 60 19.7561C57.287 19.7561 54.6556 20.0218 52.1243 20.5225L48.2903 1.14204C52.0775 0.392817 55.9929 0 60 0C64.0071 0 67.9225 0.392817 71.7097 1.14204ZM26.6631 10.1061L37.6538 26.5228C33.2566 29.4666 29.4666 33.2566 26.5228 37.6538L10.1061 26.663C14.4866 20.12 20.12 14.4866 26.6631 10.1061ZM1.14204 48.2903C0.392817 52.0775 0 55.9929 0 60C0 64.0071 0.392817 67.9225 1.14204 71.7097L20.5225 67.8757C20.0218 65.3444 19.7561 62.713 19.7561 60C19.7561 57.287 20.0218 54.6556 20.5225 52.1243L1.14204 48.2903ZM10.1061 93.3369L26.5228 82.3462C29.4666 86.7434 33.2566 90.5334 37.6538 93.4772L26.6631 109.894C20.12 105.513 14.4866 99.88 10.1061 93.3369ZM48.2903 118.858L52.1243 99.4775C54.6556 99.9782 57.287 100.244 60 100.244C62.713 100.244 65.3444 99.9782 67.8757 99.4775L71.7097 118.858C67.9225 119.607 64.0071 120 60 120C55.9929 120 52.0775 119.607 48.2903 118.858ZM93.3369 109.894L82.3462 93.4772C86.7434 90.5334 90.5334 86.7434 93.4772 82.3462L109.894 93.337C105.513 99.88 99.88 105.513 93.3369 109.894Z"
            :fill="primary"
        />
        <circle
            cx="60.0001"
            cy="60.0006"
            r="35.8537"
            :fill="'url(#paint1_linear_649_6542' + randomId + ')'"
        />
        <g clip-path="url(#clip0_649_6542)">
            <path
                d="M59.9511 56.6099C57.3559 56.6099 55.2695 58.6962 55.2695 61.2914C55.2695 63.8866 57.3559 65.9729 59.9511 65.9729C62.5462 65.9729 64.6326 63.8866 64.6326 61.2914C64.6326 58.6962 62.5462 56.6099 59.9511 56.6099Z"
                :fill="primary"
            />
            <path
                d="M70.4862 42.9725C63.9219 39.2579 55.8819 39.2579 49.3684 42.9725C49.0122 43.1761 48.7578 43.5832 48.7578 44.0411C48.7578 44.2447 48.8087 44.4482 48.9105 44.6009L54.8133 54.7781C55.0168 55.1343 55.4239 55.3379 55.831 55.3379C56.0345 55.3379 56.2381 55.287 56.3907 55.1852C58.5788 53.9639 61.2758 53.9639 63.413 55.1852C63.5657 55.287 63.7692 55.3379 63.9728 55.3379C64.3799 55.3379 64.7869 55.1343 64.9905 54.7781L70.8933 44.6009C71.1986 44.0411 71.0459 43.3287 70.4862 42.9725Z"
                :fill="primary"
            />
            <path
                d="M79.9502 60.1211H68.1955C67.534 60.1211 67.0251 60.63 67.0251 61.2915C67.0251 63.7849 65.6512 66.1257 63.514 67.3978C62.9542 67.7031 62.7507 68.4664 63.1069 69.0262L69.0097 79.2034C69.3659 79.7631 70.0783 79.9667 70.638 79.6105C77.1515 75.8449 81.2224 68.8226 81.2224 61.2915C81.1206 60.63 80.6117 60.1211 79.9502 60.1211Z"
                :fill="primary"
            />
            <path
                d="M56.4407 67.3978C54.2526 66.1257 52.8787 63.7849 52.8787 61.2915C52.8787 60.63 52.3698 60.1211 51.7083 60.1211H39.9536C39.2921 60.1211 38.7832 60.63 38.7832 61.2915C38.7832 68.8226 42.8032 75.794 49.3166 79.6105C49.5202 79.7123 49.7237 79.814 49.9273 79.814C50.3344 79.814 50.7414 79.6105 50.945 79.2543L56.8478 69.0771C57.204 68.4664 57.0004 67.754 56.4407 67.3978Z"
                :fill="primary"
            />
        </g>
        <defs>
            <linearGradient
                :id="'paint0_linear_649_6542' + randomId"
                x1="67.5015"
                y1="120"
                x2="67.5015"
                y2="0.000358403"
                gradientUnits="userSpaceOnUse"
            >
                <stop :stop-color="secondary[0]" />
                <stop offset="1" :stop-color="secondary[1]" />
            </linearGradient>
            <linearGradient
                :id="'paint1_linear_649_6542' + randomId"
                x1="64.4827"
                y1="95.8543"
                x2="64.4827"
                y2="24.1472"
                gradientUnits="userSpaceOnUse"
            >
                <stop :stop-color="tertiary[0]" />
                <stop offset="1" :stop-color="tertiary[1]" />
            </linearGradient>
            <clipPath id="clip0_649_6542">
                <rect
                    width="42.439"
                    height="42.439"
                    fill="white"
                    transform="translate(38.7832 38.7808)"
                />
            </clipPath>
        </defs>
    </svg> -->

    <svg
        id="Layer_2"
        data-name="Layer 2"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 194.24 194.24"
        v-bind="$attrs"
    >
        <g id="Layer_15_copy_2" data-name="Layer 15 copy 2">
            <g id="Layer_27" data-name="Layer 27">
                <g id="Layer_13_copy_2" data-name="Layer 13 copy 2">
                    <circle class="cls-2" cx="97.12" cy="97.12" r="97.12" />
                </g>
                <g id="Layer_3_copy_3" data-name="Layer 3 copy 3">
                    <g>
                        <path
                            class="cls-2"
                            d="M51.57,42.91l-17.23-19.89C17.6,37.22,5.74,56.99,1.61,79.46l25.85,4.96c3-16.56,11.75-31.11,24.11-41.51Z"
                        />
                        <path
                            class="cls-2"
                            d="M97.11,26.31c8.45,0,16.54,1.49,24.05,4.2l8.65-24.86C119.6,1.99,108.59,0,97.12,0s-22.48,1.99-32.69,5.65l8.65,24.86c7.51-2.71,15.6-4.19,24.04-4.19Z"
                        />
                        <path
                            class="cls-2"
                            d="M27.37,109.33l-25.86,4.96c4.02,22.56,15.85,42.43,32.57,56.71l17.23-19.89c-12.35-10.49-21.05-25.14-23.95-41.79Z"
                        />
                        <path
                            class="cls-2"
                            d="M97.11,167.93c-8.5,0-16.65-1.5-24.2-4.25l-8.65,24.85c10.26,3.69,21.32,5.7,32.85,5.7s22.59-2.01,32.85-5.7l-8.65-24.86c-7.55,2.75-15.71,4.25-24.21,4.25Z"
                        />
                        <path
                            class="cls-2"
                            d="M192.73,114.29l-25.88-4.97c-2.89,16.64-11.59,31.29-23.94,41.78l17.24,19.9c16.73-14.29,28.55-34.15,32.57-56.71Z"
                        />
                        <path
                            class="cls-2"
                            d="M166.76,84.43l25.87-4.97c-4.13-22.47-15.99-42.24-32.73-56.44l-17.24,19.9c12.36,10.4,21.1,24.95,24.1,41.5Z"
                        />
                        <path
                            class="cls-1"
                            d="M167.92,97.12c0,4.16-.38,8.24-1.07,12.2l25.88,4.97c.99-5.57,1.51-11.31,1.51-17.17s-.55-11.93-1.61-17.66l-25.87,4.97c.75,4.12,1.16,8.36,1.16,12.69Z"
                        />
                        <path
                            class="cls-1"
                            d="M26.3,97.12c0-4.34.41-8.58,1.16-12.7l-25.85-4.96c-1.05,5.73-1.61,11.63-1.61,17.66s.52,11.6,1.51,17.17l25.86-4.96c-.69-3.97-1.07-8.04-1.07-12.21Z"
                        />
                        <path
                            class="cls-1"
                            d="M142.66,42.92l17.24-19.9c-8.82-7.48-18.98-13.41-30.09-17.38l-8.65,24.86c7.92,2.86,15.18,7.1,21.5,12.41Z"
                        />
                        <path
                            class="cls-1"
                            d="M51.32,151.11l-17.23,19.89c8.83,7.54,19.04,13.53,30.18,17.54l8.65-24.85c-7.97-2.9-15.26-7.19-21.6-12.57Z"
                        />
                        <path
                            class="cls-1"
                            d="M73.07,30.5l-8.65-24.86c-11.1,3.97-21.27,9.9-30.09,17.38l17.23,19.89c6.32-5.32,13.58-9.55,21.5-12.41Z"
                        />
                        <path
                            class="cls-1"
                            d="M121.32,163.68l8.65,24.86c11.15-4.01,21.35-9.99,30.18-17.54l-17.24-19.9c-6.34,5.38-13.62,9.68-21.59,12.58Z"
                        />
                    </g>
                </g>
                <g id="Layer_8_copy_2" data-name="Layer 8 copy 2">
                    <g>
                        <path
                            class="cls-3"
                            d="M43.93,74.57l-5.89-2.04c-2.08,5-3.54,10.32-4.29,15.86l6.12,1.17c.69-5.25,2.06-10.28,4.06-14.99Z"
                        />
                        <path
                            class="cls-3"
                            d="M51.01,131.9l-4.71,4.08c3.34,4.37,7.24,8.28,11.58,11.66l4.07-4.71c-4.12-3.17-7.81-6.88-10.94-11.02Z"
                        />
                        <path
                            class="cls-3"
                            d="M39.81,104.18l-6.12,1.18c.71,5.55,2.13,10.88,4.18,15.89l5.88-2.05c-1.96-4.72-3.3-9.77-3.94-15.03Z"
                        />
                        <path
                            class="cls-3"
                            d="M62.34,51.01l-4.08-4.71c-4.37,3.34-8.28,7.24-11.66,11.58l4.71,4.07c3.17-4.12,6.88-7.81,11.02-10.94Z"
                        />
                        <path
                            class="cls-3"
                            d="M154.44,90.06l6.12-1.18c-.71-5.55-2.13-10.88-4.18-15.89l-5.88,2.05c1.96,4.72,3.3,9.77,3.94,15.03Z"
                        />
                        <path
                            class="cls-3"
                            d="M90.06,39.81l-1.18-6.12c-5.55.71-10.88,2.13-15.89,4.18l2.05,5.88c4.72-1.96,9.77-3.3,15.03-3.94Z"
                        />
                        <path
                            class="cls-3"
                            d="M143.23,62.34l4.71-4.08c-3.34-4.37-7.24-8.28-11.58-11.66l-4.07,4.71c4.12,3.17,7.81,6.88,10.94,11.02Z"
                        />
                        <path
                            class="cls-3"
                            d="M119.67,43.93l2.04-5.89c-5-2.08-10.32-3.54-15.86-4.29l-1.17,6.12c5.25.69,10.28,2.06,14.99,4.06Z"
                        />
                        <path
                            class="cls-3"
                            d="M131.9,143.23l4.08,4.71c4.37-3.34,8.28-7.24,11.66-11.58l-4.71-4.07c-3.17,4.12-6.88,7.81-11.02,10.94Z"
                        />
                        <path
                            class="cls-3"
                            d="M104.18,154.44l1.18,6.12c5.55-.71,10.88-2.13,15.89-4.18l-2.05-5.88c-4.72,1.96-9.77,3.3-15.03,3.94Z"
                        />
                        <path
                            class="cls-3"
                            d="M154.37,104.68c-.69,5.25-2.06,10.28-4.06,14.99l5.89,2.04c2.08-5,3.54-10.32,4.29-15.86l-6.12-1.17Z"
                        />
                        <path
                            class="cls-3"
                            d="M74.57,150.31l-2.04,5.89c5,2.08,10.32,3.54,15.86,4.29l1.17-6.12c-5.25-.69-10.28-2.06-14.99-4.06Z"
                        />
                        <path
                            class="cls-1"
                            d="M75.03,43.74l-2.05-5.88c-5.3,2.16-10.25,5.01-14.72,8.44l4.08,4.71c3.88-2.93,8.14-5.38,12.69-7.27Z"
                        />
                        <path
                            class="cls-1"
                            d="M51.32,61.95l-4.71-4.07c-3.47,4.45-6.36,9.37-8.56,14.65l5.89,2.04c1.93-4.54,4.42-8.77,7.39-12.62Z"
                        />
                        <path
                            class="cls-1"
                            d="M39.36,97.12c0-2.56.18-5.08.51-7.56l-6.12-1.17c-.39,2.85-.61,5.76-.61,8.73,0,2.8.2,5.54.55,8.24l6.12-1.18c-.28-2.32-.45-4.67-.45-7.06Z"
                        />
                        <path
                            class="cls-1"
                            d="M43.74,119.21l-5.88,2.05c2.16,5.3,5.01,10.25,8.44,14.72l4.71-4.08c-2.93-3.88-5.38-8.14-7.27-12.69Z"
                        />
                        <path
                            class="cls-1"
                            d="M61.95,142.93l-4.07,4.71c4.45,3.47,9.37,6.36,14.65,8.56l2.04-5.89c-4.54-1.93-8.77-4.42-12.62-7.39Z"
                        />
                        <path
                            class="cls-1"
                            d="M97.12,154.88c-2.56,0-5.08-.18-7.56-.51l-1.17,6.12c2.85.39,5.76.61,8.73.61,2.8,0,5.54-.2,8.24-.55l-1.18-6.12c-2.32.28-4.67.45-7.06.45Z"
                        />
                        <path
                            class="cls-1"
                            d="M119.21,150.5l2.05,5.88c5.3-2.16,10.25-5.01,14.72-8.44l-4.08-4.71c-3.88,2.93-8.14,5.38-12.69,7.27Z"
                        />
                        <path
                            class="cls-1"
                            d="M142.93,132.29l4.71,4.07c3.47-4.45,6.36-9.37,8.56-14.65l-5.89-2.04c-1.93,4.54-4.42,8.77-7.39,12.62Z"
                        />
                        <path
                            class="cls-1"
                            d="M154.88,97.12c0,2.56-.18,5.08-.51,7.56l6.12,1.17c.39-2.85.61-5.76.61-8.73,0-2.8-.2-5.54-.55-8.24l-6.12,1.18c.28,2.32.45,4.67.45,7.06Z"
                        />
                        <path
                            class="cls-1"
                            d="M150.5,75.03l5.88-2.05c-2.16-5.3-5.01-10.25-8.44-14.72l-4.71,4.08c2.93,3.88,5.38,8.14,7.27,12.69Z"
                        />
                        <path
                            class="cls-1"
                            d="M132.29,51.32l4.07-4.71c-4.45-3.47-9.37-6.36-14.65-8.56l-2.04,5.89c4.54,1.93,8.77,4.42,12.62,7.39Z"
                        />
                        <path
                            class="cls-1"
                            d="M97.12,39.36c2.56,0,5.08.18,7.56.51l1.17-6.12c-2.85-.39-5.76-.61-8.73-.61-2.8,0-5.54.2-8.24.55l1.18,6.12c2.32-.28,4.67-.45,7.06-.45Z"
                        />
                    </g>
                </g>
                <g id="Layer_12_copy_3" data-name="Layer 12 copy 3">
                    <path
                        class="cls-1"
                        d="M146.64,94.78c-1.19-25.52-21.66-46-47.19-47.19l-2.34-3.16-2.34,3.16c-25.52,1.19-46,21.66-47.19,47.19l-3.16,2.34,3.16,2.34c1.19,25.52,21.66,46,47.19,47.19l2.34,3.16,2.34-3.16c25.52-1.19,46-21.66,47.19-47.19l3.16-2.34-3.16-2.34ZM100.48,139.63l-3.36-9.8-3.36,9.8c-20.87-1.63-37.51-18.27-39.14-39.14l9.8-3.36-9.8-3.36c1.63-20.87,18.27-37.51,39.14-39.14l3.36,9.8,3.36-9.8c20.87,1.63,37.51,18.27,39.14,39.14l-9.8,3.36,9.8,3.36c-1.63,20.87-18.27,37.51-39.14,39.14Z"
                    />
                </g>
                <g id="Layer_14_copy_2" data-name="Layer 14 copy 2">
                    <path
                        class="cls-1"
                        d="M121.96,81.94c.42-5.89,1.36-3.43,1.87-8.99.51-5.55-2.88-7.78-3.9-8.18-1.02-.4-1.91.68-2.5,3.01s-1.44,2.25-1.44,2.25h-18.86s-18.86,0-18.86,0c0,0-.85.08-1.44-2.25s-1.48-3.41-2.5-3.01c-1.02.4-4.41,2.63-3.9,8.18.51,5.55,1.44,3.1,1.87,8.99.42,5.89-3.01,10.85-2.88,15.18.13,4.33.74,26.84,2.25,30.75,0,0,.38.89,2.37-.08,1.99-.98,7.34-3.69,23.1-3.69h0c15.76,0,21.11,2.71,23.1,3.69,1.99.98,2.37.08,2.37.08,1.51-3.9,2.12-26.42,2.25-30.75.13-4.33-3.31-9.28-2.88-15.18ZM79.79,92.89c-5.1-.02-5.74.59-5.7-.46,0,0,1.42-6.12,11.02-5.85,0,0,5.16-.03,5.68,2.24.61,2.67-5.9,4.09-11,4.07ZM114.46,92.89c-5.1.02-11.61-1.41-11-4.07.52-2.27,5.68-2.24,5.68-2.24,9.6-.27,11.02,5.85,11.02,5.85.04,1.04-.6.44-5.7.46Z"
                    />
                </g>
            </g>
        </g>
    </svg>
</template>

<style scoped>
.cls-1 {
    fill: #f2f2f2;
}

.cls-1,
.cls-2,
.cls-3 {
    stroke-width: 0px;
}

.cls-2 {
    fill: v-bind(primaryColor);
}

.cls-3 {
    fill: v-bind(secondaryColor);
}
</style>

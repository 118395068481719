export default defineAppConfig({
    ui: {
        primary: "customPrimary",
        gray: "cool",

        select: {
            variant: {
                room: "dark:bg-primary_ text-textGray-0 h-10"
            },

            color: {
                white: {
                    outline:
                        "dark:bg-foreground ring-0 dark:text-textGray-1 rounded-lg hover:opacity-80 cursor-pointer transition"
                }
            },

            icon: {
                base: "dark:text-white"
            },

            default: {
                trailingIcon: "i-material-symbols-arrow-drop-down-rounded"
            }
        },

        button: {
            color: {
                primary: {
                    // solid: "dark:bg-button-blue dark:hover:bg-button-blue dark:text-white py-3.5 px-6 text-base font-bold hover:opacity-80 transition-all justify-center"
                    solid: "bg-gradient-to-b from-[#3C5EB8] via-[#4A71D6] to-[#5C8AFF] dark:text-white py-3.5 px-6 text-base font-bold hover:opacity-80 transition-all justify-center"
                },
                secondary: {
                    solid: "dark:bg-secondary dark:hover:bg-primary dark:hover:text-white dark:text-textGray-0 transition-colors font-semibold justify-center",
                    soft: "dark:bg-main dark:hover:bg-main py-3.5 px-6 text-lg font-semibold dark:text-textGray-0",
                    ghost: "dark:hover:bg-primary dark:hover:text-white dark:text-textGray-1 transition-colors font-semibold"
                },
                blueGradient: {
                    outline:
                        "dark:bg-gradient-to-b dark:from-[#3C5EB8]/0 dark:to-[#5C8AFF]/25 dark:ring-2 dark:ring-inset dark:ring-[#3C5EB8] font-bold text-white text-lg"
                },
                greenGradient: {
                    solid: "bg-gradient-to-b from-[#2C8C52] to-[#69E39A] dark:text-white py-3.5 px-6 text-base font-bold hover:opacity-80 transition-all justify-center"
                },
                blue: {
                    solid: "dark:bg-button-blue dark:hover:bg-button-blue/80"
                }
            },
            default: {
                loadingIcon: "i-lucide-loader-circle"
            }
        },

        input: {
            color: {
                white: {
                    outline: "!ring-0 dark:bg-main font-medium"
                }
            }
        },

        selectMenu: {
            background: "dark:bg-foreground",
            ring: "ring-0",
            option: {
                active: "dark:bg-foreground dark:text-textGray-0",
                rounded: "rounded-lg",
                inactive: "dark:text-textGray-1"
            }
        },

        avatar: {
            rounded: "object-cover"
        },

        tooltip: {
            background: "dark:bg-main"
        },

        notifications: {
            wrapper: "!z-[99999999]",
            position: "bottom-0"
        },

        notification: {
            background: "dark:bg-main outline outline-2 outline-foreground"
        }
    }
});
